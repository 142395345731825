import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../providers/global.service';
import { MasterService } from '../../../providers/master.service';
import { StorageService } from '../../../providers/storage.service';
import { debounce } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'horizontal-navbar',
  templateUrl: 'horizontal-navbar.component.html',
  styleUrls: ['horizontal-navbar.component.scss'],
  host: {
    '[class.app-navbar]': 'true',
    '[class.show-overlay]': 'showOverlay'
  }
})

export class HorizontalNavbarComponent
{
  @Input() title: string;
  @Input() openedSidebar: boolean;
  @Output() sidebarState = new EventEmitter();
  showOverlay: boolean;
  gs = GlobalService;

  constructor(private _elRef: ElementRef, private router: Router, private masterService: MasterService)
  {
    this.openedSidebar = true;
    this.showOverlay = false;
  }

  open(event)
  {
    let clickedComponent = event.target.closest('.nav-item');
    let items = clickedComponent.parentElement.children;

    event.preventDefault();

    for (let i = 0; i < items.length; i++)
    {
      items[i].classList.remove('opened');
    }
    clickedComponent.classList.add('opened');

    //Add class 'show-overlay'
    this.showOverlay = true;
  }

  close(event)
  {
    let clickedComponent = event.target;
    let items = clickedComponent.parentElement.children;

    event.preventDefault();

    for (let i = 0; i < items.length; i++)
    {
      items[i].classList.remove('opened');
    }

    //Remove class 'show-overlay'
    this.showOverlay = false;
  }

  openSidebar()
  {
    this.openedSidebar = !this.openedSidebar;
    this.sidebarState.emit();
  }

  logout()
  {
    StorageService.remove('breadCrumb');
    StorageService.remove('user');
    this.router.navigate(['/login']);
  }
}
