﻿import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericService } from '../providers/generic.service';
import { MENU } from '../../settings/menu';

@Injectable()
export class GlobalService
{
  static chartConfig =
    {
      valueLabels: true,
      decimalValues: true,
      tabularForm: true,
    }

  static path: string;
  static bank = "adcb";
  static title: any;
  static selectedIndex: any;

  constructor()
  {
  }

  private static _defaultFilters: any[] = [
    { Visible: false, Value: "Wave", Name: "Wave", Multiple: false },
    { Visible:false, Value: "Bank", Name: "Employee Organization", Multiple: false, Selected: '0', List: [{ 'Id': '0', Title: "Overall ADCB" }, { 'Id': '1', Title: "Only ADCB" }, { 'Id': '2', Title: "xUNB" }] }
  ];

  private static _titleObserver = new Subject();
  static titleObservable = GlobalService._titleObserver.asObservable();
  static changeTitle(newTitle: string)
  {
    this.title = newTitle;
    this._titleObserver.next(newTitle);
  }

  private static _bankObserver = new Subject();
  static bankObservable = GlobalService._bankObserver.asObservable();
  static changeBank(newBank: string)
  {
    this._bankObserver.next(newBank);
  }

  private static _filters: any[];
  static get filters()
  {
    if (!this._filters || this._filters.length == 0)
      this._filters = this._defaultFilters;
    return this._filters;
  }
  static set filters(filters)
  {
    this._filters = filters;
    this.emitFilterChanges();
  }

  private static _filtersObserver = new Subject();
  static filtersObservable = GlobalService._filtersObserver.asObservable();
  static emitFilterChanges()
  {
    this._filtersObserver.next(this.filters);
  }


  static loadFilters(masterService)
  {
    masterService.callService("nameValue/list", true)
      .subscribe(
        (data) =>
        {
          for (let f of GlobalService.filters)
          {
            if (f.Value == 'Bank')
              continue;
            f.List = data.filter(x => x.Group == f.Value).map(function (x)
            {
              return { Id: x.Value, Title: x.Name };
            }) || [];
            f.Selected = f.Selected || [];
            f.Multiple = f.Multiple == undefined ? true : f.Multiple;
            f.Visible = f.Visible == undefined ? true : f.Visible;
            f.EnableSearch = false;
            if (f.Value == "Wave")
            {
              f.List = f.List.sort((a, b) => b.Id - a.Id);
              f.Selected = f.List[0].Id;
            }
          }
          //this.emitFilterChanges(); // This is required because now only brands are available.
        });
  }

  static resetFilters()
  {
    for (let f of GlobalService.filters)
    {
      f.List.forEach(itm =>
      {
        itm.Selected = false;
      });
    }
  }

  static serializeFilters(includeWaveFilter = true)
  {
    var strFilters = "";

    for (let f of this.filters)
    {
      if (f.List)
      {
        var selectedList = f.Selected;
        if (selectedList.length > 0)
        {
          if (!includeWaveFilter && f.Value == "Wave")
            continue;
          strFilters += ";" + f.Value + ":" + GenericService.toCsv(selectedList);
        }
      }
    }
    return strFilters;
  }

  static getMenuItemByPath(path: string)
  {
    for (let item of MENU)
    {
      if (item.routing == path)
        return item;
      if (item.sub)
      {
        for (let subItem of item.sub)
        {
          if (subItem.routing == path)
            return subItem;
        }
      }
    }
  }

  static getPageTitleByPath(path: string)
  {
    let item = this.getMenuItemByPath(path);
    return item.title;
  }
}
