import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default/default.component';
import { ExtraLayoutComponent } from './layouts/extra/extra.component';

import { PageLoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './providers/auth-guard.service';
import { PageDashboardComponent } from './pages/dashboard/dashboard.component';
import { PageDashboardComponentAttr } from './pages/dashboard-attr/dashboard-attr.component';
import { PageDirectReportComponent } from './pages/direct-report/direct-report.component';
import { PageDirectReportComponentTest } from './pages/direct-report-test/direct-report.component-test';

const defaultRoutes: Routes =
  [
    { path: 'dashboard/:id', component: PageDashboardComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard-attr/:id/:args', component: PageDashboardComponentAttr, canActivate: [AuthGuardService] },
    { path: 'direct-report-scores-old', component: PageDirectReportComponent, canActivate: [AuthGuardService] },
    { path: 'direct-report-scores', component: PageDirectReportComponentTest, canActivate: [AuthGuardService] }
  ];

const extraRoutes: Routes = [
  { path: 'login', component: PageLoginComponent }
];

export const routes: Routes =
  [
    {
      path: '',
      redirectTo: 'dashboard/manager-index',
      pathMatch: 'full'
    },
    {
      path: '',
      component: DefaultLayoutComponent,
      children: defaultRoutes
    },
    {
      path: '',
      component: ExtraLayoutComponent,
      children: extraRoutes
    },
    {
      path: '**',
      component: DefaultLayoutComponent,
      children: defaultRoutes
    }
  ];

@NgModule({
  imports: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
