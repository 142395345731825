﻿import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from '../../../providers/generic.service';
import { MENU } from '../../../../settings/menu';
import { GlobalService } from 'app/providers/global.service';
import { MasterService } from 'app/providers/master.service';
import { environment } from '../../../../environments/environment';
import { finalize, filter, debounceTime } from 'rxjs/operators';
import { StorageService } from '../../../providers/storage.service';
import { AuthService } from 'app/providers/auth.service';
import { ChartService } from 'app/providers/chart.service';

@Component({
  moduleId: module.id,
  selector: 'vertical-navbar',
  templateUrl: 'vertical-navbar.component.html',
  styleUrls: ['vertical-navbar.component.scss'],
  host: { 'class': 'vertical-navbar' }
})
export class VerticalNavbarComponent
{
  path;
  menuItems;
  subscription;
  gs = GlobalService;
  loading;
  user: any;
  selectedTabIndex: any = 0;
  organogramIds: any = [];
  loadingExcel;
  userIsAdmin: any = false;
  ajaxSubscription: any;
  config: any = { type: 'mscolumn2d', id: 'managerIndex', title: 'Manager Index', service: "manager-index-direct-report" };
  constructor(private router: Router, private masterService: MasterService, private authService: AuthService, private chartService: ChartService)
  {
      this.user = StorageService.get('user');
      this.organogramIds = this.user.OrganogramIds;
      if (JSON.parse(this.user.OrganogramPositions).Level == "Overall")
          this.userIsAdmin = true;

  }

  ngOnInit()
  {
    this.filterMenuItems();
  }

  filterMenuItems()
  {
    let items = GenericService.clone(MENU);
    this.menuItems = items.filter(x => !x.pages || x.pages.indexOf(GlobalService.path) > -1);

    this.menuItems.forEach(x =>
    {
      if (x.sub)
        x.sub = x.sub.filter(y => !y.pages || y.pages.indexOf(GlobalService.path) > -1);
    });
  }

  open(path)
  {
    if (path)
      this.path = null;
    else
      path = this.path;

    this.router.navigate([path]);
  }

  exportToPPT()
  {
    //let wavesArr = GlobalService.filters.filter(x => x.Id == "Wave")[0].Selected;
    //let wavesStr = GenericService.toCsv(wavesArr, null, ",");
    var breadCrumb = StorageService.get("breadCrumb") || [{ Id: "1", Name: "ADCB" }];
    var orgID = breadCrumb[breadCrumb.length - 1].Id;
    var url = "create-ppt?";
    var filters = GlobalService.serializeFilters();
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    if (GlobalService.bank == 'ahb')
      filters += ';Bank=4';
    url += "filters=" + filters;
    url += "&args=organogram:" + orgID;
    url += "&pageTitle=" + GlobalService.title;
    //this.config.filters = filters;
    //this.config.loading = true;
    //this.config.args = "organogram:" + this.breadCrumb[this.breadCrumb.length - 1].Id;

    this.loading = true;
    this.ajaxSubscription = this.masterService.callGetService(url).pipe(
      finalize(() =>
      {
        this.loading = false;
      }))
      .subscribe(fileName =>
      {
        location.href = environment.baseUrl + "download-ppt?fileName=" + fileName;
      },
        (err) =>
        {
          this.loading = false;
          console.log(err);
        });
  }

  exportToExcel()
  {
      if (GlobalService.title == "Manager Index By Teams" || GlobalService.title == "Manager Index By Teams") {
          //let wavesArr = GlobalService.filters.filter(x => x.Id == "Wave")[0].Selected;
          //let wavesStr = GenericService.toCsv(wavesArr, null, ",");
          var breadCrumb = StorageService.get("breadCrumb") || [{ Id: "1", Name: "ADCB" }];
          var orgID = breadCrumb[breadCrumb.length - 1].Id;
          var url = "create-excel?";
          var filters = GlobalService.serializeFilters();
          if (this.ajaxSubscription)
              this.ajaxSubscription.unsubscribe();
          if (GlobalService.bank == 'ahb')
              filters += ';Bank=4';
          url += "filters=" + filters;
          url += "&args=organogram:" + orgID;
          url += "&pageTitle=" + GlobalService.title;

          //this.config.filters = filters;
          //this.config.loading = true;
          //this.config.args = "organogram:" + this.breadCrumb[this.breadCrumb.length - 1].Id;

          this.loadingExcel = true;
          this.ajaxSubscription = this.masterService.callGetService(url).pipe(
              finalize(() => {
                  this.loadingExcel = false;
              }))
              .subscribe(fileName => {
                  location.href = environment.baseUrl + "download-file?fileName=" + fileName;
              },
              (err) => {
                  this.loadingExcel = false;
                  console.log(err);
              });
      }
      else if (GlobalService.title == "Manager Index By Direct Report") {
          
          let user = this.authService.getUser();
          
              this.config.service = "manager-index-direct-report-excel";
              
              this.loadingExcel = true;
          var filters = GlobalService.serializeFilters();
          if (this.ajaxSubscription)
              this.ajaxSubscription.unsubscribe();
          this.config.filters = filters;

          var OrganogramPositions = JSON.parse(user.OrganogramPositions);

          var tempTabFilter = (this.userIsAdmin) ? ";TabIndex:" + (GlobalService.selectedIndex ? GlobalService.selectedIndex:0) : "";

          this.config.filters += ";Group:" + user.Organogram + ";Level:" + OrganogramPositions["Level"] + tempTabFilter;
          // OrganogramPositions["Id"]

          this.config.loading = true;
          this.ajaxSubscription = this.chartService.getTable(this.config).pipe(debounceTime(500),
              finalize(() => {
                  
                  this.config.loading = false;
                  this.loadingExcel = false;
              }))
              .subscribe((res) => {
                      location.href = environment.baseUrl + 'download-excel?fileName=' + res;
                  
              },
              (err) => {
                  this.config.loading = false;
                  this.loadingExcel = false;
                  console.log(err);
              });
      }
  }


  filterChange(filter)
  {
    if (filter.Change && GlobalService[filter.Change])
      GlobalService[filter.Change](this.masterService);
  }

  applyFilters()
  {
    GlobalService.emitFilterChanges();
  }

  resetFilters()
  {
    GlobalService.resetFilters();
  }

  changeBank(bank)
  {
    GlobalService.filters[1].Visible = bank != 'ahb';
    GlobalService.changeBank(bank);
    StorageService.remove('breadcrumb');
    if (location.pathname.indexOf('/manager-index') == -1)
      this.router.navigate(['/manager-index']);
  }
}
