import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from "app/providers/auth.service";
import { Router } from '@angular/router';
import { GlobalService } from '../../../providers/global.service';

@Component({
  moduleId: module.id,
  selector: 'logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
  host: { 'class': 'app-logo' }
})
export class LogoComponent implements OnInit
{
  baseUrl;
  logoName: string;
  constructor(private router: Router, private auth: AuthService)
  {
    this.baseUrl = environment.baseUrl;
    this.logoName = GlobalService.bank;
    GlobalService.bankObservable.subscribe((bank:string) =>
    {
      this.logoName = bank;
    });
  }

  ngOnInit() { }

  clickHome()
  {     
      this.router.navigate(['/home']);
  }
}
