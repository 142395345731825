import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from 'app/providers/data.service';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'environments/environment';
import { AuthService } from 'app/providers/auth.service';

@Component({
  moduleId: module.id,
  selector: 'page-direct-report',
  templateUrl: './direct-report.component.html',
  styleUrls: ['./direct-report.component.scss']
})
export class PageDirectReportComponent implements OnDestroy
{
  title: string;
  gs = GlobalService;
  subscription: any;
  ajaxSubscription: any;
  user: any;
  organogramIds: any = [];
  currentOrganogramId;
  config: any = { type: 'mscolumn2d', id: 'managerIndex', title: 'Manager Index', service: "manager-index-direct-report" };
  parameters: any;
  excelDownload: any = false;
  searchText;

  constructor(private chartService: ChartService, private route: ActivatedRoute, private router: Router, private authService: AuthService)
  {
    this.title = "Manager Index By Direct Report";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');
    this.organogramIds = this.user.OrganogramIds;
  }

  ngOnInit()
  {
    this.route.params.subscribe(p =>
    {
      if (Object.keys(p).length > 0)
      {
        this.parameters = p;
      }
      this.subscription = GlobalService.filtersObservable.subscribe(filters =>
      {
        this.run();
      });
      if (GlobalService.filters && GlobalService.filters[0].List)
        this.run();
    });
  }

  public back()
  {
    this.router.navigate(['/manager-index']);
  }

  public run(excel = false)
  {
    let user = this.authService.getUser();
    if (excel)
    {
      this.config.service = "manager-index-direct-report-excel";
      this.excelDownload = true;
    }
    else
    {
      this.config.service = "manager-index-direct-report";
      this.excelDownload = false;
    }

    var filters = GlobalService.serializeFilters();
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    this.config.filters = filters;

    var OrganogramPositions = JSON.parse(user.OrganogramPositions);
    //if (OrganogramPositions["Id"] == 5)          
    this.config.filters += ";Group:" + OrganogramPositions["Id"] + ";Level:" + OrganogramPositions["Level"];

    this.config.loading = true;
    this.ajaxSubscription = this.chartService.getTable(this.config).pipe(debounceTime(500),
      finalize(() =>
      {
        this.excelDownload = false;
        this.config.loading = false;
      }))
      .subscribe((res) =>
      {
        if (!excel)
        {
          let that = this;
          this.config.tables = [];
          res.forEach(function (table)
          {
            let transposeTable = DataService.transposeTable(table);
            that.config.tables.push(transposeTable);
          });
        }
        else
        {
          location.href = environment.baseUrl + 'download-excel?fileName=' + res;
        }
      },
        (err) =>
        {
          console.log(err);
        });
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
   
}
