﻿import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from 'app/providers/data.service';
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from '@angular/common';

@Component({
  moduleId: module.id,
  selector: 'page-dashboard-attr',
  templateUrl: './dashboard-attr.component.html',
  styleUrls: ['./dashboard-attr.component.scss']
})
export class PageDashboardComponentAttr implements OnDestroy
{
  pageTitle: any;
  gs = GlobalService;
  subscription: any;
  ajaxSubscription: any;
  config: any = { type: 'mscolumn2d', id: 'managerIndex', title: 'Manager Index', service: "manager-index-attr" };
  parameters: any;
  round: any = Math.round;
  

  constructor(private chartService: ChartService, private route: ActivatedRoute, private router: Router, private location: Location)
  {     
    //this.title = "Attribute-Wise Index";
    //GlobalService.changeTitle(this.title);
    this.pageTitle= GlobalService.title;    
  }

  ngOnInit()
  {
    this.route.params.subscribe(p =>
    {
      if (Object.keys(p).length > 0)
      {
        this.parameters = p;
      }
      this.subscription = GlobalService.filtersObservable.subscribe(filters =>
      {
        this.run();
      });
      if (GlobalService.filters && GlobalService.filters[0].List)
        this.run();
    });
  }

  public back()
  {
    this.location.back(); 
    //this.router.navigate(['/manager-index']);

  }

  public run()
  {
    var filters = GlobalService.serializeFilters();
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();

    this.config.filters = filters;
    this.config.loading = true;
    this.config.args = "organogram:" + this.parameters["args"];
    this.config.pageTitle = this.parameters["id"];

    this.ajaxSubscription = this.chartService.getTable(this.config).pipe(debounceTime(500),
      finalize(() =>
      {
        this.config.loading = false;
      }))
      .subscribe((res) =>
      {
        res.forEach(x =>
        {
          x.Stubs[0]["Name"] = this.pageTitle;
        });
        let that = this;
        this.config.tables = [];
        
        res.forEach(function (table)
        {
          let transposeTable = DataService.transposeTable(table);
          that.config.tables.push(transposeTable);
        });
      },
        (err) =>
        {
          console.log(err);
        });
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
