export const MENU: any =
  [
    {
        title: 'Manager Index by Teams',
        icon: {
            class: 'fa fa-dollar',
            bg: '#00568A',
            color: '#fff'
        },
        routing: 'dashboard/manager-index',
    },
    {
      title: 'Manager Index by Direct Report',
      icon: {
        class: 'fa fa-dollar',
        bg: '#00568A',
        color: '#fff'
      },
      routing: '/direct-report-scores',
    }
];



