import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NiComponentsModule } from '../ni-components/ni-components.module';
import { NgSelectModule } from '@ng-select/ng-select';

import
{
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { CustomComponentsModule } from "../custom-components/custom-components.module";
import { PipesModule } from "../pipes/pipes.module";

import { PageLoginComponent } from './login/login.component';
import { PageDashboardComponent } from './dashboard/dashboard.component';
import { PageDashboardComponentAttr } from './dashboard-attr/dashboard-attr.component';
import { PageDirectReportComponent } from './direct-report/direct-report.component';
import { PageDirectReportComponentTest } from './direct-report-test/direct-report.component-test';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    NiComponentsModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    CustomComponentsModule,
    PipesModule,
    NgSelectModule
  ],
  declarations: [
    PageLoginComponent,
    PageDashboardComponent,
    PageDashboardComponentAttr,
    PageDirectReportComponent,
    PageDirectReportComponentTest
  ],
  exports: [
    PageLoginComponent,
    PageDashboardComponent,
    PageDashboardComponentAttr,
    PageDirectReportComponent,
    PageDirectReportComponentTest
  ],
  entryComponents: [
  ]
})
export class PagesModule { }
