﻿import { Component, OnDestroy, ViewChild } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from 'app/providers/data.service';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'environments/environment';
import { AuthService } from 'app/providers/auth.service';
import { GenericService } from 'app/providers/generic.service';
import { MatPaginator } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'page-direct-report-test',
  templateUrl: './direct-report.component-test.html',
  styleUrls: ['./direct-report.component-test.scss']
})
export class PageDirectReportComponentTest implements OnDestroy
{
  title: string;
  gs = GlobalService;
  subscription: any;
  ajaxSubscription: any;
  user: any;
  userIsAdmin: any = false;
  organogramIds: any = [];
  currentOrganogramId;
  config: any = { type: 'mscolumn2d', id: 'managerIndex', title: 'Manager Index', service: "manager-index-direct-report" };
  parameters: any;
  selectedTabIndex: any = 0;
  allData: any;
  tabData: any;
  tableData: any;
  excelDownload: any = false;
  searchText: any;
  totalCount: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  //mecArray: any = ["Ala'a Mohamed Atta Eraiqat", "Mohamed Naji Jayyash", "Ludovic Nobili", "Kevin Taylor", "Paul Gerard Keating", "Abdirizak Ali Mohamed", "Ali Darwish", "Deepak Khullar", "Arup Mukhopadhyay", "Simon Charles Copleston", "Mihindukulasuriya Warnapelige Chrysanthus", "Rasha Talal Mortada","Jane Livingston"];
  //mecArray: any = ["Ala'a Mohamed Atta Eraiqat", "Mohamed Naji Jayyash", "Ludovic Nobili", "Kevin Taylor", "Paul Gerard Keating", "Abdirizak Ali Mohamed", "Ali Darwish", "Deepak Khullar", "Mahmoud Ezzedine", "Deepak Ashok Rochlani", "Simon Charles Copleston", "Mihindukulasuriya Warnapelige Chrysanthus", "Rasha Talal Mortada", "Jane Livingston"];
  mecArray: any = ["Ludovic Nobili",
"Deepak Khullar",
"Mohamed Naji Jayyash",
"Rasha Talal Mortada",
"Moizes Palma Da Silva",
"Abdulaziz Almoosa",
"Abdirizak Ali Mohamed",
"Jane Livingston",
"Mahmoud Ezzedine",
"Deepak Ashok Rochlani",
"Paul Gerard Keating",
"Robbert Alexander Muller"];
    //mecArray: any = ["Ala'a Mohamed Atta Eraiqat", "Colin Sean Fraser", "SIDDIQA ABBAS JAFAR", "Kevin Taylor", "Paul Gerard Keating", "Abdirizak Ali Mohamed", "Ali Darwish", "Deepak Khullar", "Arup Mukhopadhyay", "Simon Charles Copleston", "Mihindukulasuriya Warnapelige Chrysanthus"];
  constructor(private chartService: ChartService, private route: ActivatedRoute, private router: Router, private authService: AuthService)
  {
    this.title = "Manager Index By Direct Report";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');
    this.organogramIds = this.user.OrganogramIds;
    if (JSON.parse(this.user.OrganogramPositions).Level == "Overall")
      this.userIsAdmin = true;
  }

  ngOnInit()
  {
    this.route.params.subscribe(p =>
    {
      if (Object.keys(p).length > 0)
      {
        this.parameters = p;
      }
      this.subscription = GlobalService.filtersObservable.subscribe(filters =>
      {
        this.run();
      });
      if (GlobalService.filters && GlobalService.filters[0].List)
        this.run();
    });
  }

  public back()
  {
    this.router.navigate(['/manager-index']);
  }

  public run(excel = false)
  {
      this.selectedTabIndex = 0;
    let user = this.authService.getUser();
    if (excel)
    {
      this.config.service = "manager-index-direct-report-excel";
      this.excelDownload = true;
    }
    else
    {
      this.config.service = "manager-index-direct-report";
      this.excelDownload = false;
    }

    var filters = GlobalService.serializeFilters();
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    this.config.filters = filters;

    var OrganogramPositions = JSON.parse(user.OrganogramPositions);

    var tempTabFilter = (excel && this.userIsAdmin) ? ";TabIndex:" + this.selectedTabIndex : "";

    this.config.filters += ";Group:" + user.Organogram + ";Level:" + OrganogramPositions["Level"] + tempTabFilter;

    this.config.loading = true;
    this.ajaxSubscription = this.chartService.getTable(this.config).pipe(debounceTime(500),
      finalize(() =>
      {
        this.excelDownload = false;
        this.config.loading = false;
      }))
      .subscribe((res) =>
      {
        if (!excel)
        {
          this.allData = GenericService.clone(res);
          let that = this;
          this.filterRecord();
          this.config.tables = [];
          this.tabData.forEach(function (table)
          {
            let transposeTable = DataService.transposeTable(table);
            that.config.tables.push(transposeTable);
          });
        }
        else
        {
          location.href = environment.baseUrl + 'download-excel?fileName=' + res;
        }
      },
        (err) =>
        {
          console.log(err);
        });
  }

  public tabChange()
  {
      
    this.searchText = "";
    this.filterRecord();
    this.config.tables = [];
    GlobalService.selectedIndex = this.selectedTabIndex;
    let that = this;
    this.tabData.forEach(function (table)
    {
      let transposeTable = DataService.transposeTable(table);
      that.config.tables.push(transposeTable);
    });
    
  }

  search(move = null)
  {
    setTimeout(() =>
    {
      this.config.tables = [];
      let tempTabData = GenericService.clone(this.tabData);
      let toRemove: any = [];

      for (var i = 0; i < tempTabData[0].Banners.length; i++)
      {
        var mgrName = tempTabData[0].Banners[i].Name.toUpperCase();
        if (mgrName.split("##")[0].indexOf(this.searchText.toString().toUpperCase()) == -1)
        {
          toRemove.push(i);
        }
      }

      for (var i = 0; i < tempTabData.length; i++)
      {
        let waveData = tempTabData[i];
        for (var j = toRemove.length - 1; j >= 0; j--)
        {
          let tempIndex = toRemove[j];
          waveData.Banners.splice(tempIndex, 1);
        }
      }

      this.paginator.pageIndex = move == null ? 0 : this.paginator.pageIndex;
      this.move(tempTabData);

    }, 200);

  }
  public dataReturn(min, max, tempWaveData, latestWaveData)
  {
      for (var i = 0; i < tempWaveData.length; i++) {
          let waveData = tempWaveData[i];
          waveData.Banners = latestWaveData.Banners.filter(x => Math.round(x.Values[0].Perc) < min || Math.round(x.Values[0].Perc) > max);
      }
      let banner = latestWaveData.Banners.filter(x => Math.round(x.Values[0].Perc) < min || Math.round(x.Values[0].Perc) > max);
      this.tabData = tempWaveData;
      this.totalCount = this.tabData[0].Banners.length;
  }
  public filterRecordNew() {
      this.paginator.pageIndex = 0;
      let tempWaveData = GenericService.clone(this.allData);
      if (!this.userIsAdmin) {
          this.tabData = tempWaveData;
          this.totalCount = this.tabData[0].Banners.length;
          return;
      }
      let latestWaveData = this.allData.length > 1 ? this.allData[1] : this.allData[0];

      let toRemove: any = [];
      let min = 0;
      let max = 0;
      if (this.selectedTabIndex == 0) {
          this.dataReturn(min, max, tempWaveData, latestWaveData);
      }
      else if (this.selectedTabIndex == 1)
      {
          max = 50;
          this.dataReturn(min, max, tempWaveData, latestWaveData);
      }
          
      else if (this.selectedTabIndex == 2) {
          min = 51;
          max = 65;
          this.dataReturn(min, max, tempWaveData, latestWaveData);
      }
      else if (this.selectedTabIndex == 3) {
          min = 66;
          max = 75;
          this.dataReturn(min, max, tempWaveData, latestWaveData);
      }
      else if (this.selectedTabIndex == 4) {
          min = 76;
          max = 100;
          this.dataReturn(min, max, tempWaveData, latestWaveData);
      }
      else {
          min = 0;
          max = 100;
          this.dataReturn(min, max, tempWaveData, latestWaveData);
      }
      
  }

  public filterRecord()
  {
    this.paginator.pageIndex = 0;
    let tempWaveData = GenericService.clone(this.allData);
    if (!this.userIsAdmin)
    {
      this.tabData = tempWaveData;
      this.totalCount = this.tabData[0].Banners.length;
      return;
    }
    let latestWaveData = this.allData.length > 1 ? this.allData[1] : this.allData[0];

    let toRemove: any = [];
    let min = 0;
    let max = 0;
    if (this.selectedTabIndex == 1)
      max = 50;
    else if (this.selectedTabIndex == 2)
    {
      min = 51;
      max = 65;
    }
    else if (this.selectedTabIndex == 3)
    {
      min = 66;
      max = 75;
    }
    else if (this.selectedTabIndex == 4)
    {
      min = 76;
      max = 100;
    }
    else
    {
      min = 0;
      max = 100;
    }

    if (this.selectedTabIndex == 0)
    {
        latestWaveData.Banners.forEach((banner, i) => {
            if (!this.mecArray.includes(banner.Name.split("##")[0])) {
                toRemove.push(i);
            }
        });
    }
    else
    {
      for (var i = 0; i < latestWaveData.Banners.length; i++)
      {
        if (Math.round(latestWaveData.Banners[i].Values[0].Perc) < min || Math.round(latestWaveData.Banners[i].Values[0].Perc) > max)
        {
          toRemove.push(i);
        }
      }
    }

    for (var i = 0; i < tempWaveData.length; i++)
    {
      let waveData = tempWaveData[i];
      for (var j = toRemove.length - 1; j >= 0; j--)
      {
        let tempIndex = toRemove[j];
        waveData.Banners.splice(tempIndex, 1);
      }
    }
    this.tabData = tempWaveData;
    this.totalCount = this.tabData[0].Banners.length;

  }

  move(tabData = null)
  {
    if (this.searchText && !tabData)
    {
      this.search(true);
      return;
    }
    this.config.tables = [];
    let tempTabData = GenericService.clone(this.tabData);
    tabData = tabData || tempTabData;

    this.totalCount = tabData[0].Banners.length;

    let pageNo, pageSize;
    if (this.paginator)
    {
      pageNo = this.paginator.pageIndex + 1;
      pageSize = this.paginator.pageSize;
    }
    else
    {
      pageNo = 1;
      pageSize = 10;
    }
    tabData.forEach((table, i) =>
    {
      table.Banners = table.Banners.filter((u, i) => i >= pageSize * (pageNo - 1)).filter((u, i) => i < pageSize);
    });

    let that = this;
    tabData.forEach(function (table)
    {
      let transposeTable = DataService.transposeTable(table);
      that.config.tables.push(transposeTable);
    });

  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
