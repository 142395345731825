import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Constants } from '../providers/constants';
import { StorageService } from '../providers/storage.service';
import { MasterService } from '../providers/master.service';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService
{
  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private masterService: MasterService)
  {

  }

  public isLoggedin()
  {
    var user = StorageService.get('user');
    if (!user || !user.access_token)
      return false;

    return !this.jwtHelper.isTokenExpired(user.access_token);
  }

  getUser(): any
  {
    return StorageService.get('user');
  }

  setUser(data): any
  {
    this.masterService.setUser(data);
  }

  login(userName: string, password: string): Observable<boolean>
  {
    let data = "grant_type=password&userName=" + userName + "&password=" + password + '&client_id=' + Constants.clientID;
    return this.masterService.postData('token', data, true).pipe(
      map(response =>
      {
        var user = this.setUser(response);
        return user;
      }));
  }

  refreshToken(refreshToken: any): Observable<any>
  {
    var data = "grant_type=refresh_token&refresh_token=" + refreshToken + '&client_id=' + Constants.clientID;

    return this.masterService.postData('token', data, true).pipe(
      map((response: Response) =>
      {
        var user = this.setUser(response);
        return user;
      }));
  }

  logout(): void
  {
    StorageService.remove('user');
    StorageService.remove('breadcrumb');
  }

  hasRole(roleName, user): boolean
  {
    if (!user || !user.Roles)
      return false
    else
    {
      return user.Roles.filter(x => x.Name == roleName).length > 0;
    }
  }
}
