import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { map, catchError, debounceTime, retry } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { GenericService } from './generic.service';
import { MasterService } from './master.service';
import { GlobalService } from './global.service';


@Injectable()
export class ChartService
{
  constructor(private masterService: MasterService, private http: HttpClient)
  {

  }

  public getTable(chartConfig)
  {
    var url = chartConfig.service;
    var qs = "";
    if (chartConfig.args)
      qs += "&args=" + chartConfig.args;
    if (chartConfig.filters)
      qs += "&filters=" + chartConfig.filters;
    if (chartConfig.pageTitle)
      qs += "&pageTitle=" + chartConfig.pageTitle;

    if (qs.length > 0)
    {
      if (url.indexOf('?') > -1)
        url += qs;
      else
        url += '?' + qs;
    }

    return this.masterService.callGetService(url).pipe(debounceTime(500));
  }

  tableToDataTable(table, freq = false)
  {
    let dt = [];

    table.Stubs.forEach((stub, i) =>
    {
      let row = { '': stub.Name };
      table.Banners.forEach(banner =>
      {
        row[banner.Name] = freq && banner.Values[i].Freq != undefined ? banner.Values[i].Freq : banner.Values[i].Perc;
        row[banner.Name] = row[banner.Name] + (freq && banner.Values[i].Freq != undefined ? "" : "%");
      });
      dt.push(row);
    });

    return dt;
  }

  dataTableToTable(dt)
  {
    let table = { Banners: [], Stubs: [] };

    if (dt.length == 0)
      return table;

    let keys = []; let firstKey;
    for (let row of dt)
    {
      let j = 0;
      for (var key in row)
      {
        if (j == 0)
        {
          firstKey = key;
          j++;
          continue;
        }

        keys.push(key);
      }
    }

    keys = Array.from(new Set(keys));
    keys.forEach(x =>
    {
      table.Banners.push({ Name: x, Values: [] });
    });

    dt.forEach((row, i) =>
    {
      table.Stubs.push({ Name: row[firstKey] });
    });

    table.Banners.forEach((banner, i) =>
    {
      table.Stubs.forEach((stub, j) =>
      {
        let value = 0;
        if (dt[j][banner.Name])
          value = parseFloat(dt[j][banner.Name].toString().replace(",", "")) || 0;
        banner.Values[j] = { Perc: value };
      });
    });

    return table;
  }

  sortBanners(table, bannerOrders, desc = false)
  {
    table.Banners = table.Banners.sort(function (a, b)
    {
      let x = isNaN(a.Name) ? a.Name : Number(a.Name);
      let y = isNaN(b.Name) ? b.Name : Number(b.Name);

      if (bannerOrders)
        return desc ? bannerOrders[y] - bannerOrders[x] : bannerOrders[x] - bannerOrders[y];
      else
        return desc ? y - x : x - y;
    });
    return table;
  }

  sortStubs(table, stubOrders, desc = false)
  {
    table.Banners.forEach(banner =>
    {
      banner.StubValueMap = banner.StubValueMap || {};
      table.Stubs.forEach((stub, j) =>
      {
        banner.StubValueMap[stub.Name] = banner.Values[j];
      });
    });

    table.Stubs = table.Stubs.sort(function (a, b)
    {
      let x = isNaN(a.Name) ? a.Name : Number(a.Name);
      let y = isNaN(b.Name) ? b.Name : Number(b.Name);
      if (stubOrders)
      {
        return desc ? stubOrders[y] - stubOrders[x] : stubOrders[x] - stubOrders[y];
      }
      else
        return desc ? y - x : x - y;
    });

    table.Banners.forEach(banner =>
    {
      banner.Values = [];
      table.Stubs.forEach((stub, j) =>
      {
        banner.Values.push(banner.StubValueMap[stub.Name]);
      });
    });

    return table;
  }

  sortedStubsByValue(table, bannerIndex = 0, desc = true, perc = true)
  {
    table.Banners[bannerIndex].Values.forEach((x, i) => x.OldIndex = i);
    let prop = perc ? 'Perc' : 'Freq';
    table.Banners[bannerIndex].Values = table.Banners[bannerIndex].Values.sort((n1, n2) =>
    {
      return desc ? n2[prop] - n1[prop] : n1[prop] - n2[prop];
    });

    let stubs = table.Stubs;
    table.Stubs = [];

    table.Banners[bannerIndex].Values.forEach(x =>
    {
      table.Stubs.push(stubs[x.OldIndex]);
    });

    table.Banners.forEach((banner, i) =>
    {
      if (i != bannerIndex)
      {
        let values = banner.Values;
        banner.Values = [];
        table.Banners[bannerIndex].Values.forEach(x =>
        {
          banner.Values.push(values[x.OldIndex]);
        });
      }
    });

    return table;
  }

  exportToCsv(data)
  {
    var options =
    {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: Object.keys(data[0])
    };
    //new Angular2Csv(data, 'csv', options);
  }

  static getColor(index)
  {
    if (index == undefined || index == null || index < 0 || this.colors.length <= index)
      return GenericService.getRandomColorHex();
    else
      return ChartService.colors[index];
  }

  static colors = [
    '#A569BD',
    '#969393',
    '#ba1b19',    
    '#007681',
    '#E87722',
    '#8064A2',
    '#C0504D',
    '#F79646',
    '#71B2C9',
    '#B7BF12',
    '#888B8D',
    '#F1BE48',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#316395',
    '#994499',
    '#22AA99',
    '#6633CC',
    '#E67300',
    '#252d47',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
    "#3e95cd",
    "#8e5ea2",
    "#3cba9f",
    "#e8c3b9",
    "#c45850",
  ]
}
